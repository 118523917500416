<template>
    <div class="container main">
        <top-navigation-bar
            :title="getChoiceTitle"
            right-nav="home"
        />
        <div class="d-flex route-btn-view">
            <button
                class="action-btn route-btn lg double"
                @click="showFormulaModel()"
            >
                Select a Formula
            </button>
            <h2 class="my-4">
                OR
            </h2>
            <button
                class="route-btn lg double mt-5"
                @click="navToHelp()"
            >
                Get Help Deciding
            </button>
        </div>
        <modal
            ref="modalFormula"
            title="Select Formula"
            style-class="modal-top"
            modal-type="general"
        >
            <template #body>
                <div
                    v-if="getChoiceName === 'one'"
                    class="formulaList-view"
                >
                    <button
                        v-for="(formula,key) in oneSampleFormulas"
                        :key="key"
                        class="action-btn w-100"
                        @click="navToInput(formula)"
                    >
                        <img
                            class="formulaImage-list"
                            alt="confidence interval one sample formulas"
                            :src="require(`@/assets/CIformulaImgs/${formula.name}.jpg`)"
                        >
                    </button>
                </div>
                <div
                    v-else
                    class="formulaList-view"
                >
                    <button
                        v-for="(formula,key) in twoSampleFormulas"
                        :key="key"
                        class="action-btn w-100"
                        @click="navToInput(formula)"
                    >
                        <img
                            class="formulaImage-list"
                            alt="confidence interval two sample formulas"
                            :src="require(`@/assets/CIformulaImgs/${formula.name}.jpg`)"
                        >
                    </button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import localStore from '@/store/localStore.js'
import Modal from '@/components/modal.vue'
import _ from 'lodash'

export default {
    name: 'ChoiceFormula',
    components: {
        TopNavigationBar,
        Modal
    },
    data () {
        return {
            allData: {},
            oneSampleFormulas: [
                { name: 'OneZ', title: 'One Mean' },
                { name: 'OneT', title: 'One Mean' },
                { name: 'OneP', title: 'One Proportion' },
                { name: 'OneV', title: 'One Variance' },
            ],
            twoSampleFormulas: [
                { name: 'TwoILarge', title: 'Difference in Means' },
                { name: 'TwoISmallEqual', title: 'Difference in Means' },
                { name: 'TwoISmallNotEqual', title: 'Difference in Means' },
                { name: 'TwoP', title: 'Difference in Proportions' },
                { name: 'TwoDLarge', title: 'Paired Difference in Means' },
                { name: 'TwoDSmall', title: 'Paired Difference in Means' },
                { name: 'TwoV', title: 'Two Variances' },
            ],
        }
    },
    computed: {
        getChoiceTitle () {
            return _.get(this.allData.choice, 'title', '')
        },
        getChoiceName () {
            return _.get(this.allData.choice, 'name', '')
        }
    },
    beforeMount () {
        var store = localStore.getStore('confidence-data')
        if (store) {
            _.assign(this, { allData: store })
        }
    },
    methods: {
        showFormulaModel () {
            this.$refs.modalFormula.show()
        },
        navToHelp () {
            this.$router.push({ name: 'confidence/ci-choice-help' })
        },
        navToInput (formula) {
            var { allData } = this
            _.set(this.allData, 'formula', formula)
            localStore.setStoreItem('confidence-data', allData)
            this.$router.push({ name: 'confidence/ci-input' })
        }
    },
}
</script>
